import { createSelector } from 'reselect';

import { DataDepreciationSearchParams } from '../../domain/depreciation/types';
import { KiTagListOptions, Option, OrderType } from '../../domain/schemas';

import { AfterIntroductionDateRange } from '../../components/molecules/AfterIntroductionDateRangeSelect/AfterIntroductionDateRangeSelect';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_DEPRECIATION_SETTING = 'INIT_DEPRECIATION_SETTING' as const;
const SEARCH_DEPRECIATION_SETTING = 'SEARCH_DEPRECIATION_SETTING' as const;
const SEARCH_RESET_DEPRECIATION_SETTING = 'SEARCH_RESET_DEPRECIATION_SETTING' as const;
const SEARCH_DEPRECIATION_FIELDS = 'SEARCH_DEPRECIATION_FIELDS' as const;
const SELECT_DEPRECIATION_FIELDS = 'SELECT_DEPRECIATION_FIELDS' as const;
const RENEW_DEPRECIATION_SETTING = 'RENEW_DEPRECIATION_SETTING' as const;
const SELECT_DEPRECIATION_FORM_CONDITION = 'SELECT_DEPRECIATION_FORM_CONDITION' as const;
const SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST = 'SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST' as const;
const SELECT_DEPRECIATION_CHECKED_KI_LIST = 'SELECT_DEPRECIATION_CHECKED_KI_LIST' as const;
const REMOVE_DEPRECIATION_CHECKED_KI_LIST = 'REMOVE_DEPRECIATION_CHECKED_KI_LIST' as const;
const TRIGGER_DEPRECIATION_SWAP_FIELDS = 'TRIGGER_DEPRECIATION_SWAP_FIELDS' as const;
const SELECT_DEPRECIATION_COLUMNS_ORDER = 'SELECT_DEPRECIATION_COLUMNS_ORDER' as const;
const SELECT_DEPRECIATION_NAME_FILTER = 'SELECT_DEPRECIATION_NAME_FILTER' as const;
const TRIGGER_DEPRECIATION_SORT_AND_ORDER = 'TRIGGER_DEPRECIATION_SORT_AND_ORDER' as const;
const TRIGGER_DEPRECIATION_SORT = 'TRIGGER_DEPRECIATION_SORT' as const;
const TRIGGER_DATA_DEPRECIATION_2ROW = 'TRIGGER_DATA_DEPRECIATION_2ROW' as const;
const SELECT_DEPRECIATION_KI_TAG_LIST = 'SELECT_DEPRECIATION_KI_TAG_LIST' as const;
const SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL = 'SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL' as const;
const SELECT_DEPRECIATION_KI_LIST_FILTER = 'SELECT_DEPRECIATION_KI_LIST_FILTER' as const;
const CLEAR_DEPRECIATION_KI_LIST_FILTER = 'CLEAR_DEPRECIATION_KI_LIST_FILTER' as const;
const ADD_DEPRECIATION_FIELD_FILTER = 'ADD_DEPRECIATION_FIELD_FILTER' as const;
const RESET_DEPRECIATION_FIELD_FILTER = 'RESET_DEPRECIATION_FIELD_FILTER' as const;
const SELECT_DEPRECIATION_SUB_MENU_NUMBER_FILTER = 'SELECT_DEPRECIATION_SUB_MENU_NUMBER_FILTER' as const;

export const DepreciationSettingActionTypes = {
  INIT_DEPRECIATION_SETTING,
  SEARCH_DEPRECIATION_SETTING,
  SEARCH_RESET_DEPRECIATION_SETTING,
  SEARCH_DEPRECIATION_FIELDS,
  SELECT_DEPRECIATION_FIELDS,
  RENEW_DEPRECIATION_SETTING,
  SELECT_DEPRECIATION_FORM_CONDITION,
  SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST,
  SELECT_DEPRECIATION_CHECKED_KI_LIST,
  REMOVE_DEPRECIATION_CHECKED_KI_LIST,
  TRIGGER_DEPRECIATION_SWAP_FIELDS,
  SELECT_DEPRECIATION_COLUMNS_ORDER,
  SELECT_DEPRECIATION_NAME_FILTER,
  TRIGGER_DEPRECIATION_SORT_AND_ORDER,
  TRIGGER_DEPRECIATION_SORT,
  SELECT_DEPRECIATION_KI_TAG_LIST,
  SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL,
  SELECT_DEPRECIATION_KI_LIST_FILTER,
  CLEAR_DEPRECIATION_KI_LIST_FILTER,
  ADD_DEPRECIATION_FIELD_FILTER,
  RESET_DEPRECIATION_FIELD_FILTER,
  SELECT_DEPRECIATION_SUB_MENU_NUMBER_FILTER,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initDepreciationSettingAction() {
  return {
    type: INIT_DEPRECIATION_SETTING,
  };
}

/**
 * 検索ボタンの押下
 */
function searchDepreciationSettingAction(
  searchParams: DataDepreciationSearchParams,
  selectedDateRangeParams: AfterIntroductionDateRange
) {
  return {
    type: SEARCH_DEPRECIATION_SETTING,
    payload: { searchParams, selectedDateRangeParams },
  };
}

/**
 * リセットボタンを押下した時のアクション
 */
function searchResetDepreciationSettingAction() {
  return {
    type: SEARCH_RESET_DEPRECIATION_SETTING,
  };
}

function renewDepreciationSettingAction() {
  return {
    type: RENEW_DEPRECIATION_SETTING,
  };
}

/**
 * FormConditionを変更
 */
function selectDepreciationFormCondition(
  formCondition: DepreciationSettingState['searchParams']
) {
  return {
    type: SELECT_DEPRECIATION_FORM_CONDITION,
    payload: { formCondition },
  };
}

/**
 * テーブルのチェックボックスで全選択した場合
 */
function selectDepreciationTableCheckedAllKiListAction(
  checkedKiList: { dataType: string; ki: string }[]
) {
  return {
    type: SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST,
    payload: checkedKiList,
  };
}

/*
 * テーブルのチェックボックスで選択した機種を追加
 */
function selectDepreciationCheckedKiListAction(checkedKiList: {
  dataType: string;
  ki: string;
}) {
  return {
    type: SELECT_DEPRECIATION_CHECKED_KI_LIST,
    payload: checkedKiList,
  };
}

/*
 * テーブルのチェックボックスで選択した機種を削除
 */
function removeDepreciationCheckedKiListAction(
  checkedKiList: {
    dataType: string;
    ki: string;
  }[]
) {
  return {
    type: REMOVE_DEPRECIATION_CHECKED_KI_LIST,
    payload: checkedKiList,
  };
}

/**
 * 表示項目の変更
 */
function searchDepreciationFieldAction(fields: Option[]) {
  return {
    type: SEARCH_DEPRECIATION_FIELDS,
    payload: { fields },
  };
}

/**
 * 表示項目を変更(fetchしない)
 */
function selectDepreciationFieldsAction(fields: string[]) {
  return {
    type: SELECT_DEPRECIATION_FIELDS,
    payload: { fields },
  };
}

/**
 * 表示項目の並び順を入替
 */
function triggerDepreciationSwapFieldsAction(
  draggedId: string,
  droppedId: string
) {
  return {
    type: TRIGGER_DEPRECIATION_SWAP_FIELDS,
    payload: { draggedId, droppedId },
  };
}

/**
 * 表示項目の並び順を変更
 */
function selectDepreciationColumnsOrderAction(columnsOrder: string[]) {
  return {
    type: SELECT_DEPRECIATION_COLUMNS_ORDER,
    payload: { columnsOrder },
  };
}

/**
 * 表示項目の列の非表示
 */
function addDepreciationTableFilterAction(columnCode: string) {
  return {
    type: ADD_DEPRECIATION_FIELD_FILTER,
    payload: { columnCode },
  };
}

/**
 * 表示項目の列の非表示を解除
 */
function resetDepreciationTableFilterAction() {
  return {
    type: RESET_DEPRECIATION_FIELD_FILTER,
  };
}

/**
 * 機種名フィルタを変更
 */
function selectDepreciationNameFilterAction(name: string) {
  return {
    type: SELECT_DEPRECIATION_NAME_FILTER,
    payload: { name },
  };
}

/**
 * サブメニューからのソート変更
 */
function triggerDepreciationSortAndOrderAction(sort: string, order: OrderType) {
  return {
    type: TRIGGER_DEPRECIATION_SORT_AND_ORDER,
    payload: { sort, order },
  };
}

/**
 * サブメニュー内数値フィルタを変更
 */
function selectDepreciationSubMenuNumberFilterAction(subMenuNumberFilter: {
  [field: string]: {
    minimumNumber: number | undefined;
    maximumNumber: number | undefined;
  };
}) {
  return {
    type: SELECT_DEPRECIATION_SUB_MENU_NUMBER_FILTER,
    payload: { subMenuNumberFilter },
  };
}

/**
 * 表示項目にあるソートボタン押下
 */
function triggerDepreciationSortAction(sort: string) {
  return {
    type: TRIGGER_DEPRECIATION_SORT,
    payload: { sort },
  };
}

/**
 * 店舗行を表示・非表示する
 */
function triggerDepreciation2RowAction() {
  return {
    type: TRIGGER_DATA_DEPRECIATION_2ROW,
    payload: {},
  };
}

/**
 * 機種タグ絞り込みを変更
 */
function selectDepreciationKiTagListAction(kiTagList: KiTagListOptions[]) {
  return {
    type: SELECT_DEPRECIATION_KI_TAG_LIST,
    payload: { kiTagList },
  };
}

/**
 * 機種行への機種タグ表示機能のフラグを変更
 */
function selectDepreciationShowKiTagLabelAction(showKiTagLabel: boolean) {
  return {
    type: SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL,
    payload: { showKiTagLabel },
  };
}

/**
 * 機種絞り込みを変更
 */
function selectDepreciationKiListFilterAction(kiList: string[]) {
  return {
    type: SELECT_DEPRECIATION_KI_LIST_FILTER,
    payload: { kiList },
  };
}

/**
 * 機種絞り込みのリセット
 */
function clearDepreciationKiListFilterAction() {
  return {
    type: CLEAR_DEPRECIATION_KI_LIST_FILTER,
  };
}

export const DepreciationSettingActionCreators = {
  initDepreciationSettingAction,
  searchDepreciationSettingAction,
  searchResetDepreciationSettingAction,
  searchDepreciationFieldAction,
  selectDepreciationFieldsAction,
  renewDepreciationSettingAction,
  selectDepreciationFormCondition,
  selectDepreciationTableCheckedAllKiListAction,
  selectDepreciationCheckedKiListAction,
  removeDepreciationCheckedKiListAction,
  triggerDepreciationSwapFieldsAction,
  selectDepreciationColumnsOrderAction,
  selectDepreciationNameFilterAction,
  triggerDepreciationSortAndOrderAction,
  triggerDepreciationSortAction,
  triggerDepreciation2RowAction,
  selectDepreciationKiTagListAction,
  selectDepreciationShowKiTagLabelAction,
  selectDepreciationKiListFilterAction,
  clearDepreciationKiListFilterAction,
  addDepreciationTableFilterAction,
  resetDepreciationTableFilterAction,
  selectDepreciationSubMenuNumberFilterAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitDepreciationSettingAction = ReturnType<
  typeof initDepreciationSettingAction
>;

export type SearchDepreciationSettingAction = ReturnType<
  typeof searchDepreciationSettingAction
>;

export type SearchResetDepreciationSettingAction = ReturnType<
  typeof searchResetDepreciationSettingAction
>;

export type SearchDepreciationFieldsAction = ReturnType<
  typeof searchDepreciationFieldAction
>;

type SelectDepreciationFieldsAction = ReturnType<
  typeof selectDepreciationFieldsAction
>;
type RenewDepreciationSettingAction = ReturnType<
  typeof renewDepreciationSettingAction
>;

type SelectDepreciationFormConditionAction = ReturnType<
  typeof selectDepreciationFormCondition
>;

type SelectDepreciationTableCheckedAllKiListAction = ReturnType<
  typeof selectDepreciationTableCheckedAllKiListAction
>;
type SelectDepreciationCheckedKiListAction = ReturnType<
  typeof selectDepreciationCheckedKiListAction
>;

type RemoveDepreciationCheckedKiListAction = ReturnType<
  typeof removeDepreciationCheckedKiListAction
>;

export type TriggerDepreciationSwapFieldsAction = ReturnType<
  typeof triggerDepreciationSwapFieldsAction
>;

type SelectDepreciationColumnsOrderAction = ReturnType<
  typeof selectDepreciationColumnsOrderAction
>;

type SelectDepreciationNameFilterAction = ReturnType<
  typeof selectDepreciationNameFilterAction
>;

type TriggerDepreciationSortAndOrderAction = ReturnType<
  typeof triggerDepreciationSortAndOrderAction
>;

type TriggerDepreciationSortAction = ReturnType<
  typeof triggerDepreciationSortAction
>;

type SelectDepreciationKiTagListAction = ReturnType<
  typeof selectDepreciationKiTagListAction
>;

type SelectDepreciationShowKiTagLabelAction = ReturnType<
  typeof selectDepreciationShowKiTagLabelAction
>;
type SelectDepreciationKiListFilterAction = ReturnType<
  typeof selectDepreciationKiListFilterAction
>;

type ClearDepreciationKiListFilterAction = ReturnType<
  typeof clearDepreciationKiListFilterAction
>;

type AddDepreciationFieldFilterAction = ReturnType<
  typeof addDepreciationTableFilterAction
>;

type ResetDepreciationFieldFilterAction = ReturnType<
  typeof resetDepreciationTableFilterAction
>;

type SelectDepreciationSubMenuNumberFilterAction = ReturnType<
  typeof selectDepreciationSubMenuNumberFilterAction
>;

type DepreciationSettingActions =
  | InitDepreciationSettingAction
  | SearchDepreciationSettingAction
  | SearchResetDepreciationSettingAction
  | SearchDepreciationFieldsAction
  | SelectDepreciationFieldsAction
  | RenewDepreciationSettingAction
  | SelectDepreciationFormConditionAction
  | SelectDepreciationTableCheckedAllKiListAction
  | SelectDepreciationCheckedKiListAction
  | RemoveDepreciationCheckedKiListAction
  | TriggerDepreciationSwapFieldsAction
  | SelectDepreciationColumnsOrderAction
  | SelectDepreciationNameFilterAction
  | TriggerDepreciationSortAndOrderAction
  | TriggerDepreciationSortAction
  | SelectDepreciationKiTagListAction
  | SelectDepreciationShowKiTagLabelAction
  | SelectDepreciationKiListFilterAction
  | ClearDepreciationKiListFilterAction
  | AddDepreciationFieldFilterAction
  | ResetDepreciationFieldFilterAction
  | SelectDepreciationSubMenuNumberFilterAction;
/* ---------------------------------------------------------------
 * State
 */

export type DepreciationSettingState = {
  searchParams: DataDepreciationSearchParams | undefined;
  /**
   * テーブルのチェックボックスで選択されている機種
   */
  checkedKiList: {
    dataType: string;
    ki: string;
  }[];
  /**
   * 表示項目の列順
   */
  columnsOrder: string[];
  /**
   * テーブルの非表示項目一覧
   */
  fieldsFilter: string[];
  /**
   * サブメニュー内数値フィルタ
   */
  subMenuNumberFilter:
    | {
        [field: string]: {
          minimumNumber: number | undefined;
          maximumNumber: number | undefined;
        };
      }
    | undefined;
  /**
   * 機種名フィルタ
   */
  nameFilter: string | undefined;
  /**
   * 機種絞り込み
   */
  kiListFilter: string[];
  /**
   * タグ絞込で選択されているタグリスト
   */
  kiTagList: KiTagListOptions[];
  /**
   * 機種行への機種タグ表示機能のフラグ
   */
  showKiTagLabel: boolean;
  /**
   * 検索条件の日付範囲
   */
  selectedDateRangeParams: AfterIntroductionDateRange;
};

const initialState: DepreciationSettingState = {
  searchParams: undefined,
  selectedDateRangeParams: '1年',
  checkedKiList: [],
  columnsOrder: [],
  subMenuNumberFilter: {},
  fieldsFilter: [],
  nameFilter: undefined,
  kiListFilter: [],
  kiTagList: [],
  showKiTagLabel: false,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別償却] 設定情報を取得する
 */
export function depreciationSettingSelector(rootState: {
  depreciationSettings: DepreciationSettingState;
}) {
  return rootState.depreciationSettings;
}

/**
 * [機種別償却] 現在選択されている検索条件を取得する
 */

export const depreciationSearchParamsSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.searchParams
);

/**
 * [機種別償却] 機種行への機種タグ表示機能のフラグを取得する
 */
export const depreciationShowKiTagLabelSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.showKiTagLabel
);

/**
 * [機種別償却] タグ絞込で選択されているタグリストを取得する
 */
export const depreciationKiTagListSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.kiTagList
);

/**
 * [機種別償却] 表示項目の列順を取得する
 */
export const depreciationColumnsOrderSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.columnsOrder
);

/**
 * [機種別償却] フィルターされている機種名を取得する
 */
export const depreciationNameFilterSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.nameFilter
);

/**
 * [機種別償却] テーブルの選択されている出力を取得する
 */
export const depreciationMainFieldSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.searchParams?.mainField
);

/**
 * [機種別償却] チェックボックで選択されている機種を取得する
 */
export const depreciationSelectCheckedKiListSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.checkedKiList
);

/**
 * [機種別償却] 機種絞り込みされている機種リストを取得する
 */
export const depreciationKiListFilterSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.kiListFilter
);

/**
 * [機種別償却] 機種絞り込みされているかどうかを取得する
 */
export const isDepreciationKiListFilterSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.kiListFilter.length !== 0
);

/**
 * [機種別償却] 列を非表示で非表示にされている表示項目を取得する
 */
export const depreciationFieldsFilterSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.fieldsFilter
);

/**
 * [機種別償却] 数値フィルターを取得する
 */
export const depreciationSubMenuNumberFilterSelector = createSelector(
  depreciationSettingSelector,
  (depreciationSettings) => depreciationSettings.subMenuNumberFilter
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function depreciationSettingReducer(
  state = initialState,
  action: DepreciationSettingActions
): DepreciationSettingState {
  switch (action.type) {
    case 'SEARCH_DEPRECIATION_SETTING':
      return {
        ...state,
        searchParams: action.payload.searchParams,
        selectedDateRangeParams: action.payload.selectedDateRangeParams,
      };
    case 'RENEW_DEPRECIATION_SETTING':
      return initialState;
    default:
      return state;
    case 'SELECT_DEPRECIATION_FORM_CONDITION':
      return {
        ...state,
        searchParams: action.payload.formCondition,
      };
    case 'SELECT_DEPRECIATION_CHECKED_TABLE_ALL_KI_LIST':
      return {
        ...state,
        checkedKiList: [...state.checkedKiList, ...action.payload],
      };
    case 'SELECT_DEPRECIATION_CHECKED_KI_LIST':
      return {
        ...state,
        checkedKiList: [...state.checkedKiList, action.payload],
      };
    case 'REMOVE_DEPRECIATION_CHECKED_KI_LIST':
      return {
        ...state,
        checkedKiList: action.payload,
      };
    case 'SELECT_DEPRECIATION_COLUMNS_ORDER':
      return {
        ...state,
        columnsOrder: action.payload.columnsOrder,
      };
    case 'SELECT_DEPRECIATION_NAME_FILTER':
      return {
        ...state,
        nameFilter: action.payload.name,
      };
    case 'SELECT_DEPRECIATION_KI_TAG_LIST':
      return {
        ...state,
        kiTagList: action.payload.kiTagList,
      };
    case 'SELECT_DEPRECIATION_SHOW_KI_TAG_LABEL':
      return {
        ...state,
        showKiTagLabel: action.payload.showKiTagLabel,
      };
    case 'SELECT_DEPRECIATION_KI_LIST_FILTER':
      return {
        ...state,
        kiListFilter: action.payload.kiList,
      };
    case 'CLEAR_DEPRECIATION_KI_LIST_FILTER':
      return {
        ...state,
        kiListFilter: [],
      };
    case 'ADD_DEPRECIATION_FIELD_FILTER':
      return {
        ...state,
        fieldsFilter: [...state.fieldsFilter, action.payload.columnCode],
      };
    case 'RESET_DEPRECIATION_FIELD_FILTER':
      return {
        ...state,
        fieldsFilter: initialState.fieldsFilter,
      };
    case 'SELECT_DEPRECIATION_SUB_MENU_NUMBER_FILTER':
      return {
        ...state,
        subMenuNumberFilter: {
          ...state.subMenuNumberFilter,
          ...action.payload.subMenuNumberFilter,
        },
      };
    case 'SELECT_DEPRECIATION_FIELDS':
      return {
        ...state,
        searchParams: {
          ...(state.searchParams ? state.searchParams : {}),
          fields: action.payload.fields,
        },
      };
  }
}
